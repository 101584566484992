import { v4 as uuid } from "uuid"

export const API = process.env.API_URL || "https://chatbotecci.mybluemix.net"
//export const API = "http://localhost:3000"
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

export const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    //body: raw,
    redirect: 'follow'
};

export const APIRequest = async (endpoint, options) => {
    let response = await fetch(`${API}${endpoint}`, options).then(response => response.json());
    //console.log("utils response: ", response, response.length)
    if (response.length === 0) {
        response = [{}]
    }
    return await response
}

export function formatMinutes(minutes) {
    return minutes < 10 ? `0${minutes}` : minutes;
}

export function formatSeconds(seconds) {
    return seconds < 10 ? `0${seconds}` : seconds;
}
export function generateKey() {
    return uuid();
}

