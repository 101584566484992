import React from 'react';
import MediaMessage from './MediaMessage';
import "assets/css/black-dashboard-react.scoped.css";
import "assets/scss/black-dashboard-react.scoped.scss";

class Message extends React.Component {
    formatter = new Intl.DateTimeFormat('es', { day: "numeric", month: 'long', hour: "2-digit", minute: "2-digit" });

    linkPreview = (text) => {
        let firstSplit = text.split(/http/i)
        let stringUrl = 'http' + firstSplit[1].split(" ", 1)
        return <>{text.split(stringUrl)[0]} <a href={stringUrl}>{stringUrl}</a> {text.split(stringUrl)[1]}</>
        // if (text.search(/http/i) !== -1) {
        //     console.log(text.substring(text.search(/http/i)).split(" ")[0])
        //     let response = await fetch(text.substring(text.search("/http/i")).split(" ")[0], { mode: "same-origin", headers: { 'Content-Type': 'application/json' } }) //.then(response => response.json());
        //     console.log(JSON.stringify(response.json()))
        // }
    }

    componentDidMount() {
        //this.linkPreview(this.props.text)
    }

     bold(text) {
        let accum = []
        let bold = text.split('*')
        bold.forEach((element,index) => {
            if (index % 2 !== 0) {
                accum.push(<b key={index}>{element}</b>)
            } else {
                accum.push(element)
            }
        });
        return accum
    }

    render() {
        return <>
            <div className="message-item">
                {this.props?.senderName && <div><b>{this.props?.senderName}</b></div>}
                {this.props?.text?.search(/http/i) === -1 && <span style={{ whiteSpace: "pre-line" }}>{this.bold(this.props?.text)}</span>}
                {(this.props?.text?.search(/http/i) !== -1 && this.props?.text?.search(/http/i) !== undefined) &&
                    <span style={{ whiteSpace: "pre-line" }}>{this.linkPreview(this.props?.text)}</span> /**<MediaMessage mediaUrl={this.props?.text?.substring(this.props?.text?.search(/http/i)).split(" ")[0]}></MediaMessage> */}
                {this.props.mediaUrl && <MediaMessage mediaUrl={this.props?.mediaUrl}></MediaMessage>}
                <br />
                {this.props?.date && <div style={{ textAlign: this.props.senderType === "User" ? "left" : "right" }}>
                    <small>{this.formatter.format(new Date(this.props?.date))}</small>
                </div>}
                {!this.props?.date && <div style={{ textAlign: this.props.senderType === "User" ? "left" : "right" }}>
                    <small>{this.formatter.format(new Date())} </small>
                </div>}
            </div>
        </>
    }
}

export default Message;