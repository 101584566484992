import React from 'react';
import FsLightbox from 'fslightbox-react';
import "assets/css/black-dashboard-react.scoped.css";
import "assets/scss/black-dashboard-react.scoped.scss";

class MediaMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggler: false
        }
    }
    youtubeId = (url) => {
        let id = undefined
        if (url?.search(/youtu.be/i) !== -1) {
            id = url?.split("/").at(-1)
        } else if (url?.search(/youtube.com/i) !== -1) {
            id = url?.split("=").at(-1)
        }
        return id
    }

    getSources = () => {
        let youtubeId = this.youtubeId(this.props.mediaUrl)
        console.log(`\n\nENTRO A ALGO!!!!\n${this.props?.mediaUrl + " " + this.props?.mediaUrl.split('.').at(-1)}\n`)
        // if (youtubeId !== undefined) {
        //     return <iframe title="message-url" src={`https://www.youtube.com/embed/${youtubeId}`} width="1920px" height="1080px"
        //         frameBorder="0" allow="fullscreen" allowFullScreen />
        // } else 
        if (this.props?.mediaUrl.split('.').at(-1) === 'mp4') {
            console.log("entra a video")
            return <video controls="" autoplay="" name="media">
                <source src={this.props.mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        } else if (this.props?.mediaUrl.split('.').at(-1) === 'mpeg') {
            console.log("entra a audio.")
            return <audio controls>
                <source src={this.props.mediaUrl} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio>
        } else {
            console.log("entra a else")
            return <iframe title="message-url" width="1920px" height="1080px" frameBorder="0" allow="fullscreen" allowFullScreen
                src={this.youtubeId(this.props.mediaUrl) !== undefined ? `https://www.youtube.com/embed/${youtubeId}` : this.props.mediaUrl} />
        }
    }

    render() {
        return <>
            <div className='fullscreen-image'>
                <button style={{ background: 'transparent', border: '1' }} onClick={() => this.setState({ toggler: !this.state.toggler })}> {/*setToggler(!toggler)}>*/}

                    {(this.props?.mediaUrl?.split('.').at(-1) === 'jpeg' || this.props?.mediaUrl?.split('.').at(-1) === 'jpg') && <img
                        src={this.props?.mediaUrl}
                        alt="Resource not loading"
                        style={{
                            position: 'relative',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: '90%',
                            width: '90%',
                            objectFit: 'contain'
                        }}
                    />}
                    {this.props?.mediaUrl?.split('.').at(-1) === 'mp4' && <video controls autoPlay={false} name="media" width="300rem" height="220rem">
                        <source src={this.props.mediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>}
                    {this.props?.mediaUrl?.split('.').at(-1) === 'mpeg' && <audio controls autoPlay={false}>
                        <source src={this.props.mediaUrl} type="audio/mpeg" />
                        Your browser does not support the audio tag.
                    </audio>}
                    {this.props?.mediaUrl?.split('.').at(-1) !== 'jpg' &&
                        this.props?.mediaUrl?.split('.').at(-1) !== 'jpeg' &&
                        this.props?.mediaUrl?.split('.').at(-1) !== 'mp4' &&
                        this.props?.mediaUrl?.split('.').at(-1) !== 'mpeg' && <iframe title='message-media' src={this.youtubeId(this.props.mediaUrl) !== undefined ? `https://www.youtube.com/embed/${this.youtubeId(this.props.mediaUrl)}` : this.props.mediaUrl} width="300rem" height="200rem"
                            frameBorder="0" allow="fullscreen" allowFullScreen />}
                </button>
                {/* {this.props.mediaUrl.split('.').at(-1) === 'jpeg'
                    || this.props.mediaUrl.split('.').at(-1) === 'jpeg' ? <FsLightbox
                    toggler={this.state.toggler}
                    sources={[<iframe src={this.props.mediaUrl} width="1920px" height="1080px"
                        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />]}
                /> : null}
                {this.props.mediaUrl.split('.').at(-1) === 'mp4' ? <FsLightbox
                    toggler={this.state.toggler}
                    sources={[<iframe src={this.props.mediaUrl} width="1920px" height="1080px"
                        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />]}
                /> : null} */}
                {this.props?.mediaUrl?.search(/http/i) !== -1 ? <FsLightbox
                    toggler={this.state.toggler}
                    sources={[<iframe title="message-url" src={this.youtubeId(this.props.mediaUrl) !== undefined ? `https://www.youtube.com/embed/${this.youtubeId(this.props.mediaUrl)}` : this.props.mediaUrl} width="1920px" height="1080px"
                        frameBorder="0" allow="fullscreen" allowFullScreen />]}
                //sourcess={[this.getSources()]}
                /> : null}
                {/* <video controls="" autoplay="" name="media"><source src="https://uploads-chatbot-ecci.s3.us-south.cloud-object-storage.appdomain.cloud/Video%20Publicitario%20Manitas%20Solidarias.mp4" type="video/mp4"></video>*/}
            </div>
            {/* <div className="message-item">
                {this.props.senderName && <div><b>{this.props.senderName}</b></div>}
                <span>{this.props.text}</span>
                {this.props.mediaUrl && (this.props.mediaUrl.split('.').at(-1) === 'jpeg') && <div> <img src={this.props.mediaUrl} alt="imagee" width="500" height="300" onClick={this.maximizeImage} /> </div>}
            </div> */}
        </>
    }
}

export default MediaMessage;