import React from 'react';
import Channel from './Channel';

class ChannelList extends React.Component {

    handleClick = id => {
        this.props.onSelectChannel(id);
    }

    render() {
        let list = <div className="no-content-message">There is no channels to show</div>;
        if (this.props.channels) {
            list = this.props.channels.map(c => <Channel key={c.id} id={c.id} name={c.name} participants={c.participants} onClick={this.handleClick}></Channel>);
        }

        return <>
            <div className="channel-list">
                {list}
            </div>
        </>;
    }
}

export default ChannelList;