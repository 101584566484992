import React from "react";
import './chatform.scoped.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { requestOptions, APIRequest } from "variables/utils";
import Load from "components/Load/Load";

import {
    Input,
    Label,
    Container,
    Row,
    Col,
} from "reactstrap";
class ChatForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            phone: ""
        }
    }




    labelFocus = () => {
        var labels = document.getElementsByClassName('input-form')
        for (var i = 0; i < labels.length; i++) {
            labels[i].addEventListener('keyup', function () {
                if (this.value.length >= 1) {
                    this.nextElementSibling.classList.add('active')

                } else {
                    this.nextElementSibling.classList.remove('active')
                }
            })
        }
    }

    handdleEnter = e => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            this.handdleSubmit()
        }
    }

    handdleSubmit = () => {
        let indicative_number = document.getElementsByClassName("input-form-phone")[0].getAttribute("value").split("+")
        let phonenumber = indicative_number[1].replaceAll(" ", "")
        let name = document.getElementsByClassName("input-form")[0].value
        let email = document.getElementsByClassName("input-form")[1].value    
        let prefix = document.getElementsByClassName("input-form-phone")[0].getAttribute("value").split(" ")
        prefix = prefix[0]
        if (document.getElementsByClassName("input-form")[0].value !== '' && document.getElementsByClassName("input-form")[1].value !== "") {
            if(prefix === "+57" && phonenumber.length >= 12){
                if (/^[-\w.%+_]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)) {
                    if (/^[a-z,A-Z, ]{3,30}$/.test(name)) {
                        this.requestData({
                            phonenumber,
                            platform: "Web",
                            username: name,
                            email
                        })
    
                        this.setState({
                            loading: true
                        })
    
    
                    } else {
                        alert("Por favor ingrese un nombre de usuario valido.")
                    }
                } else {
                    alert("El correo digitado no es valido.")
                }
            }else if (prefix !== "+57" && phonenumber.length >= 8){
                if (/^[-\w.%+_]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)) {
                    if (/^[a-z,A-Z, ]{3,30}$/.test(name)) {
                        this.requestData({
                            phonenumber,
                            platform: "Web",
                            username: name,
                            email
                        })
    
                        this.setState({
                            loading: true
                        })
    
    
                    } else {
                        alert("Por favor, Ingrese un nombre de usuario valido.")
                    }
                } else {
                    alert("El correo digitado no es valido.")
                }
            }else{
                alert("Por favor, Digite un número de teléfono valido.")
            }
        } else {
            alert("Por favor, Ingrese todos los datos solicitados.")
        }
    }


    render() {
        return <>
            {
                this.state.loading === true ? <div className="Load"><Load /></div> : <div className="card-form">
                    <div>
                        <h3 id="h3" style={{ "textAlign": "center", "fontWeight": "bold" }}>ChatBot ECCI!</h3>
                        <p id="p">¡Hola! Bienvenido/a al ChatBot ECCI, para continuar, por favor diligencie los siguientes campos con la información requerida.</p>
                    </div>
                    <Container className="container-form" >
                        <Row>
                            <Col style={{ "marginTop": "18px" }}>
                                <Label className="label-form-media"> Nombre </Label>
                                <Input type="text" className="input-form" autoComplete="off" required onFocus={this.labelFocus} onKeyPress={this.handdleEnter} />
                                <Label className="label-form"> Nombre </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="label-form-media"> Correo </Label>
                                <Input type="email" className="input-form" autoComplete="off" required onFocus={this.labelFocus} onKeyPress={this.handdleEnter} />
                                <Label className="label-form"> Correo </Label>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="label-form-media"> Celular </Label>
                                <PhoneInput
                                    dropdownClass="drop-down"
                                    inputClass="input-form-phone" country={"co"}
                                    inputStyle={{ background: "rgba(2, 75, 274, 0.05) " }} onKeyDown={this.handdleEnter}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                />
                                <Label id="label-phone-form"> Celular </Label>
                            </Col>
                        </Row>
                    </Container>
                    <div id="container-button">
                        <button type="submit" id="send" onClick={this.handdleSubmit} value="Send">Send</button>
                    </div>
                </div>
            }
        </>
    }

    requestData = async (user) => {
        localStorage.setItem('USER_NAME', user?.username);
        let data = {
            user
        }
        let userResponse = await APIRequest('/updateUserProfile/', { ...requestOptions, body: JSON.stringify(data) })
        console.log("userResponse", userResponse)
        if (userResponse?.success) {
            if (this.state?.channel?.channel_id == null || this.state?.channel?.channel_id === undefined) {
                let ticketData = {
                    platform: user?.platform,
                    phonenumber: user?.phonenumber
                }
                this.props?.configureSocket()
                await this.props?.handleCreateTicket(ticketData)
                //this.props.onSendMessage(this.props?.channel?.channel_id, "Hola")
                //console.log("after created: ", this.state?.channel)
            }
        }
    }
}

export default ChatForm;